/* main.css */
.hero {
    background-color: #222;
    color: #fff;
    padding: 2rem;
    text-align: center;
  }
  
  section {
    padding: 2rem;
  }
  
  section h2 {
    margin-bottom: 1rem;
  }
  