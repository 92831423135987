/* footer.css */
footer {
    background-color: #222;
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .social-links {
    display: flex;
  }
  
  .social-links a {
    color: #fff;
    text-decoration: none;
    margin-left: 1rem;
  }
  
  .social-links a:hover {
    color: #aaa;
  }
  