header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222;
    padding: 1rem;
  }
  
  header h1 {
    color: #fff;
  }
  
  nav ul {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  nav ul li a {
    color: #fff;
    text-decoration: none;
    margin-left: 1rem;
  }
  
  nav ul li a:hover {
    color: #aaa;
  }
  